'use client'
import { usePathname } from "next/navigation";
import Link from "next/link";

const HeaderBanner = ({isMobile}) => {
  const urls = ['/']
  const pathname = usePathname();


  const showBanner = () => {
    return pathname !== '/prodavnica' && pathname !== '/products/crux-ashwagandha' && pathname !== '/products/crux-kolagen' && pathname !== '/products/colagen-x-ashwagandha'
  }

  if(!showBanner()) {
    return null
  }

  return <Link href={'/akcija'}>
    <div style={{background: '#7C764C'}} className={`fixed left-[0px] top-[76px] z-10 w-[100vw]`}>
      {
        !isMobile &&
        <div className={'flex items-center justify-center flex-row h-[40px]'}>
          <span style={{color: '#F7F4ED'}} className={`font-[300] text-[8px] md:text-[14px] leading-[24px] tracking-[1.5px] md:tracking-[2px]`}>SLAVIMO 4. rođendan</span>
          <span style={{background: '#F7F4ED'}} className={`inline-block h-[10px] w-[1px] ml-[15px] mr-[15px]`}></span>
          <span style={{color: '#F7F4ED'}} className={`font-[700] text-[8px] md:text-[14px] leading-[24px] tracking-[1.5px] md:tracking-[2px] uppercase`}>OSVOJI CRUX PURE PROIZVOD ILI GODIŠNJU ZALIHU!</span>
        </div>
      }
      {
        isMobile && <div className={'flex items-center justify-center flex-row h-[40px]'}>
          <span style={{color: '#F7F4ED'}} className={`font-[400] text-[10px] md:text-[14px] leading-[16px] text-center tracking-[1.5px] md:tracking-[2px] uppercase`}>SLAVIMO 4. rođendan <br/> <strong>OSVOJI CRUX PURE PROIZVOD ILI GODIŠNJU ZALIHU!</strong></span>
        </div>
      }
    </div>
  </Link>
}

export default HeaderBanner;
