"use client";
import ContainerWrapper from "@/app/components/common/ContainerWrapper";
import NewsletterIcon from "@/app/components/icons/NewsletterIcon";
import MainContainerWrapper from "@/app/components/common/MainContainerWrapper";
import React, { useState } from "react";
import { Inter } from "next/font/google";

import "@/app/styles/global.scss";
import { subscribeUser } from "@/app/services/NewsletterService";
import Link from "next/link";
import { isValidEmail } from "@/app/utils/validation";
import { usePathname } from "next/navigation";

const inter = Inter({ subsets: ["latin"] });

const FooterPage = ({ data }) => {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(null);
  const [error, setError] = useState(null);
  const path = usePathname();

  const isPinkOctober = data?.dataOptions?.acf?.pink_footer;

  const handleSubscribeToNewsletter = async (email) => {
    const success = await subscribeUser(email);
    if (success) {
      setSubscribed("Uspešno ste se prijavili na naš Newsletter");
      setError(null);
    } else {
      setSubscribed("Već ste prijavljeni na naš Newsletter");
      setError(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isValidEmail(email)) {
      await handleSubscribeToNewsletter(email);
    } else {
      setError("Unesite validan e-mail.");
    }
  };
  const routesForPinkOctober = ["/prodavnica", "/borba-protiv-raka-dojke"];
  const productPages = ['/products/crux-kolagen', '/products/crux-ashwagandha']

  return <MainContainerWrapper>

    <div className={`${productPages.includes(path) ? 'force-white-bg' : ''}`}/>


    <div
      className={`${(isPinkOctober && routesForPinkOctober.includes(path)) ? "bg-cruxPink" : "bg-cruxGreen"} w-full xsm:mx-[50px] rounded-t-[30px] relative  bottom-0`}>
      <div className="flex flex-col max-w-[1200px] mx-auto">
        <div
          className={`${(isPinkOctober && routesForPinkOctober.includes(path)) ? "border-[#E493AA]" : "border-[#F7F4ED]"} flex xsm:flex-col lg:flex-row justify-between mt-[50px] mb-[20px] w-full border-b  max-w-[1000px] xl:max-w-[1100px] mx-auto`}>
          <div className="flex flex-col xsm:w-full xsm:px-[20px] lg:w-fit items-start gap-[20px]">
            <img className={"xsm:w-[160px] lg:w-[250px]"}
                 src={(isPinkOctober && routesForPinkOctober.includes(path)) ? data.dataOptions?.acf?.pink_logo : data.dataOptions?.acf?.logo_image} />
            <div
              className={`${(isPinkOctober && routesForPinkOctober.includes(path)) ? "text-[#626261]" : "text-white"} 'flex xsm:flex-col lg:flex-row gap-0 xl:gap-[20px] font-thin`}>
              <div className="flex flex-row items-start gap-[20px] min-w-[200px]">
                <img
                  src={(isPinkOctober && routesForPinkOctober.includes(path)) ? data.dataOptions?.acf?.home_icon_gray : data.dataOptions?.acf?.home_icon} />
                <div className="flex flex-col">
                  <p>{data.dataOptions?.acf?.company_name}</p>
                  <p>{data.dataOptions?.acf?.address}</p>
                  <p>{data.dataOptions?.acf?.city}</p>
                  <p>{data.dataOptions?.acf?.mb_text}</p>
                  <p>{data.dataOptions?.acf?.pib_text}</p>
                </div>
              </div>
              <div className="flex flex-col gap-[10px]">
                <div className="flex flex-row items-start gap-[20px]">
                  <img className="w-[18px] h-[20px]"
                       src={(isPinkOctober && routesForPinkOctober.includes(path)) ? data.dataOptions?.acf?.email_icon_gray : data.dataOptions?.acf?.email_icon} />
                  <Link href={`mailto:${data.dataOptions?.acf.email}`}>
                    <p>{data.dataOptions?.acf.email}</p>
                  </Link>
                </div>
                <div className="flex flex-row items-start gap-[20px]">
                  <img className=""
                       src={(isPinkOctober && routesForPinkOctober.includes(path)) ? data?.dataOptions?.acf?.phone_icon_gray : data.dataOptions?.acf?.phone_icon} />
                  <p>{data.dataOptions?.acf?.phone}</p>
                </div>
              </div>
            </div>
            <div className="flex  flex-row gap-[20px] mb-[20px]">
              <a href="https://www.instagram.com/crux_pure/" target="_blank" rel="noopener noreferrer">
                <img
                  src={(isPinkOctober && routesForPinkOctober.includes(path)) ? data.dataOptions.acf.social_media_repeater_gray[0]?.instagram_icon : data.dataOptions.acf.social_media_repeater[0]?.instagram_icon}
                  alt="Instagram" />
              </a>
              <a href="https://www.facebook.com/cruxpure.rs" target="_blank" rel="noopener noreferrer">
                <img
                  src={(isPinkOctober && routesForPinkOctober.includes(path)) ? data.dataOptions.acf.social_media_repeater_gray[0]?.facebook_icon : data.dataOptions.acf.social_media_repeater[0]?.facebook_icon}
                  alt="Facebook" />
              </a>
            </div>
          </div>
          <div className="flex flex-col xsm:w-full lg:w-fit gap-[2px] px-[20px] md:px-0 mb-[10px] md:mb-0">
            <div
              className={`${(isPinkOctober && routesForPinkOctober.includes(path)) ? "text-[#E493AA]" : "text-[#E2CDAF]"} font-[700] leading-[26px] tracking-[3.2px]`}>NAVIGACIJA
            </div>
            <div
              className={`${(isPinkOctober && routesForPinkOctober.includes(path)) ? "border-[#E493AA]" : "border-[#F7F4ED]"} border-b w-[50px] mt-[6px] mb-[11px]`}></div>
            <div
              className={`${(isPinkOctober && routesForPinkOctober.includes(path)) ? "text-[#626261]" : "text-white"} flex font-[100] leading-[28px] tracking-[0.8px]`}>
              <div className={`${inter.className} flex flex-col`}>
                <a href="/" className="mr-6">Početna</a>
                <a href="/blog" className="mr-6">Blog</a>
                <a href="/prodavnica" className="mr-6">Prodavnica</a>
                <a href="/kontakt" className="mr-6">Kontakt</a>
              </div>
              <div className={`${inter.className} flex flex-col`}>
                <a href="/utisci" className="mr-6">Iskustva</a>
                <a href="/najcesca-pitanja" className="mr-6">FAQ</a>
                <a href="/klinicke-studije" className="mr-6">Kliničke studije</a>
                <a href="/video-review" className="mr-6">Video recenzija</a>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col justify-start xsm:w-full lg:w-fit gap-[2px] px-[20px] md:px-0 mb-[10px] md:mb-0">
            <div
              className={`${(isPinkOctober && routesForPinkOctober.includes(path)) ? "text-[#E493AA]" : "text-[#E2CDAF]"}  font-[700] leading-[26px] tracking-[3.2px]`}>KORISNI
              LINKOVI
            </div>
            <div
              className={`${(isPinkOctober && routesForPinkOctober.includes(path)) ? "border-[#E493AA]" : "border-[#F7F4ED]"} border-b w-[50px] mt-[6px] mb-[11px]`}></div>
            <div
              className={`${inter.className} ${(isPinkOctober && routesForPinkOctober.includes(path)) ? "text-[#626261]" : "text-white"} flex flex-col font-[100] leading-[28px] tracking-[0.8px]`}>
              <a href="/dostava-i-postarina" className="mr-6">Dostava i poštarina</a>
              <a href="/politika-privatnosti" className="mr-6">Uslovi kupovine</a>
              <a href="/pravilnik-o-reklamaciji" className="mr-6">Pravilnik o reklamaciji</a>
              <a href="/deklaracije" className="mr-6">Deklaracija</a>
              <a href="/prodajna-mesta" className="mr-6">Prodajna mesta</a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full bg-[#ffffff0D] hidden md:flex flex-row justify-between md:py-[30px] md:px-[10%] 3xl:px-[15%] relative">
        <div className="flex items-center gap-[10px]">
          <a href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html" target="_blank"
             rel="noopener noreferrer">
            <img className="w-[92px] xxl:w-[112px]" src="/idCheckFooter.svg" alt="ID CHECK" />
          </a>
          <a href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html" target="_blank"
             rel="noopener noreferrer">
            <img className="w-[32px] xxl:w-[34px]" src="/visa_secure1.svg" alt="visa secure" />
          </a>
        </div>
        <div className="absolute left-1/2 transform -translate-x-1/2">
          <a href="https://www.bancaintesa.rs/" target="_blank" rel="noopener noreferrer">
            {/*<img src='/intesalogo3.png' alt='banca intesa logo'/>*/}
            <img src="/banca intesa_color.png" alt="banca intesa logo"
                 className="w-[200px] xxl:w-[226px] bg-cruxBeige px-[25px] py-[5px] rounded-[10px]" />
          </a>
        </div>
        <div className="flex gap-[10px]">
          <img className="lg:w-[38px] xxl:w-[46px]" src="/master1Footer.svg" alt="master" />
          <img className="lg:w-[38px] xxl:w-[46px]" src="/master2Footer.svg" alt="maestro" />
          <img className="lg:w-[40px] xxl:w-[52px]" src="/DinaFooter.svg" alt="dina" />
          <img className="lg:w-[40px] xxl:w-[58px]" src="/VisaFooter.svg" alt="visa" />
          <img className="lg:w-[34px] xxl:w-[38px]" src="/americanExpressFooter.svg" alt="american express" />
        </div>
      </div>
      <div
        className="w-full bg-[#ffffff0D] flex md:hidden flex-col justify-center items-center pt-[10px] md:px-[10%] 3xl:px-[15%] relative gap-[20px]">
        <div className="flex items-center gap-[40px]">
          <a href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html" target="_blank"
             rel="noopener noreferrer">
            <img src="/idCheckFooter.svg" alt="ID CHECK" />
          </a>
          <a href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html" target="_blank"
             rel="noopener noreferrer">
            <img src="/visa_secure1.svg" alt="visa secure" />
          </a>
        </div>
        <div className="w-full mx-auto flex justify-center items-center">
          <a href="https://www.bancaintesa.rs/" target="_blank" rel="noopener noreferrer">
            <img src="/bancaIntesaLogo.svg" alt="banca intesa logo" />
          </a>
        </div>
        <div className="flex gap-[10px]">
          <img src="/master1Footer.svg" alt="master" />
          <img src="/master2Footer.svg" alt="maestro" />
          <img src="/DinaFooter.svg" alt="dina" />
          <img src="/VisaFooter.svg" alt="visa" />
          <img src="/americanExpressFooter.svg" alt="american express" />
        </div>
      </div>
      <div className="mx-[8%]">
        <p
          className={`${(isPinkOctober && routesForPinkOctober.includes(path)) ? "text-[#626261]" : "text-white"} font-[100] tracking-[2px] mt-[20px] mb-[10px]`}>{data.dataOptions.acf?.rights_reserved}</p>
      </div>
    </div>
  </MainContainerWrapper>;
};
export default FooterPage;
